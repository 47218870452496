import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";

import Logo from "../images/best-logo-transparent.png";
import MinecraftMainGreen from "../images/minecraft-main-green.jpg";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const axios = require("axios");

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signatures: null,
      goal: null,
      signers: null,
      numberofsigners: 6,
    };
  }

  componentDidMount() {
    axios
      .get(
        "https://spreadsheets.google.com/feeds/cells/1NG7Q5C9V0lnbbMHTjdPZ2FQaimvf4EutRkg4BsHbN6U/1/public/full?alt=json"
      )
      .then((res) => {
        this.setState({
          signatures: res.data.feed.entry[0].content.$t,
          goal: res.data.feed.entry[1].content.$t,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        "https://spreadsheets.google.com/feeds/list/1NG7Q5C9V0lnbbMHTjdPZ2FQaimvf4EutRkg4BsHbN6U/2/public/full?alt=json"
      )
      .then((res) => {
        this.setState({
          signers: res.data.feed.entry
            .map((obj) => {
              return {
                name: obj.gsx$name.$t,
                timestamp: obj.gsx$timestamp.$t,
                comment: obj.gsx$comment.$t,
                year:
                  Number.isInteger(parseInt(obj.gsx$year.$t)) &&
                  2020 < parseInt(obj.gsx$year.$t) < 2026
                    ? `'${obj.gsx$year.$t.slice(-2)}`
                    : "",
              };
            })
            .reverse(),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <main>
        <Helmet>
          <title>Petition for a Gaming Space | Brown Esports</title>
          <meta
            name="description"
            content="Gaming and esports at Brown University is massively popular, but is one of the least accessible recreational activities on campus. Sign our petition today!"
          />
          <meta name="theme-color" content="#C00404" />
          <meta
            property="og:title"
            content="Create a Space for Gaming and Esports at Brown University"
          />
          <meta
            property="og:description"
            content="Gaming and esports at Brown University is massively popular, but is one of the least accessible recreational activities on campus. Sign our petition today!"
          />
          <meta property="og:image" content={MinecraftMainGreen} />
          <link
            rel="icon"
            type="image/png"
            href="https://brownesports.org/img/favicon-32x32.png"
            sizes="32x32"
          ></link>
        </Helmet>
        <Navbar />

        <div
          className="min-w-full relative"
          style={{
            height: "45vh",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${MinecraftMainGreen})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          alt="Brown University Main Green in Minecraft"
        >
          <div
            className="text-center absolute w-80"
            style={{
              top: "105%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="h-32 w-32 mx-auto bg-gray-900 rounded-full flex items-center border-4 border-red-600">
              <img
                className="h-24 mx-auto"
                src={Logo}
                alt="Brown Esports Logo"
              ></img>
            </div>
            <p className="font-medium text-2xl">Brown Esports</p>
            <p className="text-lg text-gray-600">
              Competitive and Casual Gaming at Brown University
            </p>
          </div>
        </div>

        <div className="max-w-4xl mx-auto mt-36 px-2">
          <h1 className="font-bold text-center text-3xl md:text-4xl mb-4">
            Petition for an esports and gaming space at Brown University
          </h1>
          <p className="text-center text-lg md:text-xl text-gray-600">
            <span className="font-bold">
              {this.state.signatures ? this.state.signatures : "--"} signatures
            </span>
            {"  "}· Dynamic Goal of {this.state.goal ? this.state.goal : "--"}
          </p>
          <div className="grid md:gap-6 md:grid-cols-3">
            <div className="block md:hidden mt-2">
              <Signatures
                signatures={this.state.signatures}
                goal={this.state.goal}
              />
            </div>
            <div className="md:col-span-2">
              <div className="mb-6">
                <StaticQuery
                  query={graphql`
                    query HomeQuery {
                      markdownRemark(
                        frontmatter: { title: { eq: "Homepage" } }
                      ) {
                        html
                      }
                    }
                  `}
                  render={(data) => (
                    <div
                      className="prose max-w-none"
                      dangerouslySetInnerHTML={{
                        __html: data.markdownRemark.html,
                      }}
                    ></div>
                  )}
                />
              </div>
              <div>
                <h2 className="text-2xl md:text-3xl font-bold">
                  Reasons for Signing
                </h2>
                {this.state.signers
                  ? this.state.signers
                      .slice(0, this.state.numberofsigners)
                      .map((signer, i) => {
                        return (
                          <div
                            className={
                              "py-4" +
                              (i + 1 === this.state.numberofsigners ||
                              i + 1 === this.state.signers?.length
                                ? ""
                                : " border-b border-gray-200")
                            }
                            key={"signer" + i}
                          >
                            <p className="text-sm text-gray-600">
                              <span className="font-semibold text-black">
                                {signer.name}
                                {signer.year ? ` ${signer.year}` : null}
                              </span>
                              <span className="mx-2">&middot;</span>
                              {timeDifference(
                                new Date(),
                                new Date(signer.timestamp + "-04:00")
                              )}
                            </p>
                            {signer.comment ? (
                              <p className="text-gray-700 mt-2">
                                {signer.comment}
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                  : null}
                <button
                  className={
                    "items-center border border-gray-300 px-4 py-2 rounded-md mt-2 hover:bg-gray-50 duration-100 transition-colors cursor-pointer w-full" +
                    (this.state.signers?.length > this.state.numberofsigners
                      ? " flex"
                      : " hidden")
                  }
                  onClick={() => {
                    this.setState({
                      numberofsigners: this.state.numberofsigners + 4,
                    });
                  }}
                >
                  <p className="font-bold text-sm flex-grow">
                    View more reasons for signing
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="black"
                    width="24px"
                    height="24px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="hidden md:block md:col-span-1">
              <p className="font-bold text-center text-lg mt-6 mb-2">
                Signature Count
              </p>
              <Signatures
                signatures={this.state.signatures}
                goal={this.state.goal}
              />
              <a
                className="min-w-full mt-4 px-4 py-2 font-medium rounded-md shadow-md text-gray-200 bg-gradient-to-br bg-gray-900 hover:bg-gray-700 transition-colors duration-100 inline-flex"
                href="https://bit.ly/best-space-petition"
              >
                <div className="flex items-center mx-auto">
                  Sign the Petition
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="mb-16 md:mb-0">
          <Footer />
        </div>
        <div className="fixed block md:hidden bottom-0 w-full bg-gray-50 border-t border-gray-200 p-2 h-16">
          <a
            className="min-w-full px-4 py-2 font-medium rounded-md shadow-md text-gray-200 bg-gradient-to-br bg-gray-900 hover:bg-gray-700 transition-colors duration-100 inline-flex"
            href="https://bit.ly/best-space-petition"
          >
            <div className="flex items-center mx-auto">Sign the Petition</div>
          </a>
        </div>
      </main>
    );
  }
}

export default index;

class Signatures extends Component {
  render() {
    return (
      <>
        <div className="w-full bg-gray-300 rounded-full h-4 mb-1 overflow-hidden">
          <div
            className="h-full animated-gradient" /* bg-gradient-to-br from-red-600 to-yellow-500 */
            style={{
              width: `${
                parseInt(this.props.signatures) /
                (parseInt(this.props.goal) / 100)
              }%`,
            }}
          ></div>
        </div>
        <div className="flex justify-between">
          <p className="uppercase text-xs text-gray-600">
            {this.props.signatures ? this.props.signatures : "--"} signed
          </p>
          <p className="uppercase text-xs text-gray-600">
            {this.props.goal ? this.props.goal : "--"} goal
          </p>
        </div>
      </>
    );
  }
}

// taken and adpated from here: https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time
function timeDifference(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return (
      Math.round(elapsed / 1000) +
      (Math.round(elapsed / 1000) === 1 ? " second ago" : " seconds ago")
    );
  } else if (elapsed < msPerHour) {
    return (
      Math.round(elapsed / msPerMinute) +
      (Math.round(elapsed / msPerMinute) === 1 ? " minute ago" : " minutes ago")
    );
  } else if (elapsed < msPerDay) {
    return (
      Math.round(elapsed / msPerHour) +
      (Math.round(elapsed / msPerHour) === 1 ? " hour ago" : " hours ago")
    );
  } else if (elapsed < msPerMonth) {
    return (
      Math.round(elapsed / msPerDay) +
      (Math.round(elapsed / msPerDay) === 1 ? " day ago" : " days ago")
    );
  } else if (elapsed < msPerYear) {
    return (
      Math.round(elapsed / msPerMonth) +
      (Math.round(elapsed / msPerMonth) === 1 ? " month ago" : " months ago")
    );
  } else {
    return (
      Math.round(elapsed / msPerYear) +
      (Math.round(elapsed / msPerYear) === 1 ? " year ago" : " years ago")
    );
  }
}
